import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Footer from '../components/footer'
import './leistungen.css'

const Leistungen = (props) => {
  return (
    <div className="leistungen-container">
      <Helmet>
        <title>
          Leistungen - reNature People: Naturschutz & Nachhaltigkeit für Mensch
          und Natur
        </title>
        <meta
          name="description"
          content="Naturverbundene Lösungen für Bildung, Beratung und Gestaltung. Nachhaltiges Verhalten fördern. Naturpädagogik, Umgestaltung, Renaturierung. Kontaktieren Sie uns für Hilfe und Informationen."
        />
        <meta
          property="og:title"
          content="Leistungen - reNature People: Naturschutz &amp; Nachhaltigkeit für Mensch und Natur"
        />
        <meta
          property="og:description"
          content="Naturverbundene Lösungen für Bildung, Beratung und Gestaltung. Nachhaltiges Verhalten fördern. Naturpädagogik, Umgestaltung, Renaturierung. Kontaktieren Sie uns für Hilfe und Informationen."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/97781406-fd67-48a1-b9e9-35531dc96503/26880b92-dc51-45d7-8076-8acf5157488d?org_if_sml=1"
        />
      </Helmet>
      <div className="leistungen-hero">
        <Header rootClassName="header-root-class-name3"></Header>
        <div className="leistungen-bg"></div>
      </div>
      <div className="leistungen-post-details">
        <h3 className="leistungen-text">Leistungen</h3>
        <span className="leistungen-text01">
          <span>
            „Wir haben die Natur von unseren Eltern geerbt. Wir haben sie aber
            auch von unseren Kindern geliehen.“
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <br></br>
          <span>Richard von Weizsäcker</span>
        </span>
      </div>
      <div className="leistungen-container01">
        <div className="leistungen-story">
          <div className="leistungen-container02">
            <div className="leistungen-container03">
              <h1 className="leistungen-text05 TextXL">
                Wissensvermittlung und Bildung
              </h1>
              <span className="leistungen-text06">
                Unsere Arbeit zielt darauf ab, das Bewusstsein für die
                Verbindung zwischen Mensch und Natur zu fördern, um ein
                nachhaltiges Verhalten zu entwickeln. Wir bieten
                naturpädagogische Veranstaltungen an, die an die Bedürfnisse der
                Zielgruppe angepasst sind und eine aktive Mitgestaltung
                ermöglichen. Unsere Bausteine können in die pädagogische Arbeit
                integriert werden und umfassen praktische Arbeit an Projekten.
              </span>
            </div>
            <div className="leistungen-container04"></div>
          </div>
          <div className="leistungen-container05">
            <div className="leistungen-container06"></div>
            <div className="leistungen-container07">
              <h1 className="leistungen-text07 TextXL">
                Beratung und Konzeption
              </h1>
              <span className="leistungen-text08">
                Wir bieten Hilfe und Unterstützung bei der naturnahen
                Umgestaltung und Pflege von bestehenden Flächen wie Gärten,
                Grünflächen, Betriebsgeländen und Schulgärten sowie bei der
                Renaturierung von versiegelten oder verarmten Flächen. Wir sind
                Experten für Fragen rund um Landflächen, von Artenschutz bis
                Nachbarschaftsrecht und Zubehör im naturnahen Garten.
                Kontaktieren Sie uns für konkrete Hilfestellung. Wir übermitteln
                Ihnen gerne ein ausführliches Leistungsportfolio.
              </span>
            </div>
          </div>
          <div className="leistungen-container08">
            <div className="leistungen-container09">
              <h1 className="leistungen-text09 TextXL">
                Gestaltung und Umsetzung
              </h1>
              <span className="leistungen-text10">
                {' '}
                Unser umfangreiches Dienstleistungspaket richtet sich an
                Privatgartenbesitzer, Firmen und Bildungs- und soziale
                Einrichtungen mit eigenen (Grün-)flächen oder Zugang zu
                Freiflächen. Wir bieten Beratung, Konzeption, Planung vor Ort,
                Ausführung und langfristige Nachpflege. Optional können
                pädagogisch unterstützende Bausteine integriert werden.
                Kontaktieren Sie uns für mehr Informationen. Wir übermitteln
                Ihnen gerne ein ausführliches Leistungsportfolio.
              </span>
            </div>
            <div className="leistungen-container10"></div>
          </div>
          <div className="leistungen-container11">
            <div className="leistungen-container12"></div>
            <div className="leistungen-container13">
              <h1 className="leistungen-text11 TextXL">
                <span>Vernetzen</span>
                <br></br>
              </h1>
              <span className="leistungen-text14">
                {' '}
                Unsere Organisation arbeitet daran, nachhaltige
                länderübergreifende Strukturen aufzubauen, indem wir uns mit
                nationalen und internationalen Partnern vernetzen und
                Investitionen in gemeinsame Naturschutzprojekte, Renaturierung
                von Flächen und Schutz von Lebensräumen für Pflanzen, Tiere und
                Menschen fördern. Wir helfen auch bei der Schaffung
                landwirtschaftlicher Kooperationen und dem Zugang zu Märkten für
                den direkten Verkauf von nachhaltigen Landwirtschaftsprodukten.
                Kontaktieren Sie uns für weitere Informationen.
              </span>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Leistungen
