import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Footer from '../components/footer'
import './datenschutz.css'

const Datenschutz = (props) => {
  return (
    <div className="datenschutz-container">
      <Helmet>
        <title>
          Datenschutz - reNature People: Naturschutz & Nachhaltigkeit für Mensch
          und Natur
        </title>
        <meta
          name="description"
          content="Willkommen bei reNature People - der gemeinnützigen Non-Profit Organisation für Naturschutz und harmonisches Miteinander von Mensch und Natur. Schütze die Natur, unterstütze unsere Projekte weltweit."
        />
        <meta
          property="og:title"
          content="Datenschutz - reNature People: Naturschutz &amp; Nachhaltigkeit für Mensch und Natur"
        />
        <meta
          property="og:description"
          content="Eine seit 2021 arbeitende gemeinnützige Non-Profit Organisation, die sich dem Ziel des Naturschutzes und der Förderung eines harmonischen Miteinanders von Mensch und Natur verschrieben hat."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/97781406-fd67-48a1-b9e9-35531dc96503/26880b92-dc51-45d7-8076-8acf5157488d?org_if_sml=1"
        />
      </Helmet>
      <div className="datenschutz-image">
        <Header></Header>
        <div className="datenschutz-bg"></div>
      </div>
      <div className="datenschutz-container01">
        <div className="datenschutz-container02">
          <h3 className="datenschutz-text">Datenschutzerklärung</h3>
          <span className="datenschutz-text001">
            <span>
              Lesen Sie hier unsere Erklärung zu datenschutzrechtlichen Punkten
              für die Nutzung
            </span>
            <br></br>
            <span>
              unserer Website www.renaturepeople.deunter Berücksichtigung der
              Datenschutz-
            </span>
            <br></br>
            <span>
              Grundverordnung (DS-GVO). Verantwortlich ist die reNature People
              gGmbH
            </span>
          </span>
          <div className="datenschutz-container03"></div>
          <h3 className="datenschutz-text007">Präambel</h3>
          <span className="datenschutz-text008">
            <span>
              Die auf dieser Internetseite vorgenommene Datenverarbeitung
              geschieht, um
            </span>
            <br></br>
            <span>
              Besuchern die Inhalte der Internetseite richtig präsentieren zu
              können und zu
            </span>
            <br></br>
            <span>
              gewährleisten, dass sie sicher Kontakt zu uns aufnehmen können.
            </span>
          </span>
          <div className="datenschutz-container04"></div>
          <h3 className="datenschutz-text014">
            <span>
              Erhebung/Speicherung von vorgangsbezogenen Zugriffsdaten und deren
            </span>
            <br></br>
            <span>Verwendungszweck</span>
          </h3>
          <span className="datenschutz-text018">
            <span>
              Beim Öffnen unserer Internetseite wird technisch notwendig ein
              Drittanbieter mit
            </span>
            <br></br>
            <span>
              eingeschaltet, die Übermittlung der Webseite geschieht mit Hilfe
              eines Internet-
            </span>
            <br></br>
            <span>
              Server-Betreibers (Hoster). Durch den auf Ihrem Endgerät
              eingesetzten Browser
            </span>
            <br></br>
            <span>
              werden automatisch Daten an den Server dieses Hosters übertragen.
              Dabei handelt
            </span>
            <br></br>
            <span>
              es sich um vorgangsbezogene Zugriffsdaten. Diese Zugriffsdaten
              werden zeitweise
            </span>
            <br></br>
            <span>
              in sogenannten Logfiles datentechnisch in einem Protokoll
              vorgehalten. Bei diesen
            </span>
            <br></br>
            <span>
              Verfahren werden folgende Datenarten erfasst und bis zum Zeitpunkt
              einer
            </span>
            <br></br>
            <span>Löschungsroutine gespeichert:</span>
            <br className="datenschutz-text034"></br>
            <br></br>
            <span>Datum und Uhrzeit des Webseiten-Zugriffs</span>
            <br className="datenschutz-text037"></br>
            <br></br>
            <span>
              Typ des Browsers und ggf. das Betriebssystem sowie der Name des
              Providers
            </span>
            <br className="datenschutz-text040"></br>
            <br></br>
            <span>Name und URL der aufgerufenen Seite/Datei</span>
            <br className="datenschutz-text043"></br>
            <br></br>
            <span>
              Zugriffsstatus (Datei übertragen, Datei nicht gefunden etc.)
            </span>
            <br className="datenschutz-text046"></br>
            <br></br>
            <span>IP-Adresse des zugreifenden Host-/Clientsystems</span>
            <br className="datenschutz-text049"></br>
            <br></br>
            <span>
              Bei Referals: Webseite, von der aus der Zugriff via Link erfolgt
            </span>
            <br></br>
            <br></br>
            <span>
              Die oben aufgeführten Zugriffsdaten werden zu folgenden Zwecken
              verarbeitet:
            </span>
            <br></br>
            <br></br>
            <span>
              Anbietung und Aufrechterhaltung eines funktionierenden
              Webseiten-Betriebs
            </span>
            <br className="datenschutz-text058"></br>
            <br></br>
            <span>
              Anbietung und Aufrechterhaltung einer problemlosen
              Webseiten-Nutzung
            </span>
            <br className="datenschutz-text061"></br>
            <br></br>
            <span>
              {' '}
              Auswertung zum Monitoring des technischen Betriebs und Systems
              sowie zu
            </span>
            <br></br>
            <span>administrativen Zwecken</span>
            <br className="datenschutz-text066"></br>
            <br></br>
            <span>
              Die datenschutzrechtliche Grundlage ist der weithin bekannte Art.
              6 Abs. 1, S. 1 der
            </span>
            <br></br>
            <span>
              DSVGO. D. h. diese Zugriffsdaten werden von uns nicht dafür
              eingesetzt, um
            </span>
            <br></br>
            <span>
              Rückschlüsse auf Ihre Person ziehen zu können oder ein „Profiling“
              zu betreiben.
            </span>
            <br></br>
            <span>
              Mögliche Folgen, wenn man seine Zugriffsdaten nicht bereitstellen
              möchte, wären,
            </span>
            <br></br>
            <span>
              dass unsere Webseite nicht vollständig oder teilweise nicht
              aufgerufen werden und
            </span>
            <br></br>
            <span>
              unsere Inhalte nicht der intendierten Form präsentiert werden
              könnten.
            </span>
            <br></br>
            <br></br>
          </span>
          <div className="datenschutz-container05"></div>
          <h3 className="datenschutz-text081">
            <span>
              Erhebung/Speicherung von personenbezogenen Daten bei Formularen
              der
            </span>
            <br></br>
            <span>Seite</span>
          </h3>
          <span className="datenschutz-text085">
            <span>
              Zur Kontaktaufnahme oder Abfragung von Informationen bietet unsere
              Internetseite
            </span>
            <br></br>
            <span>
              Ihnen die Möglichkeit, über bereitgestellte Formulare mit uns zu
              korrespondieren.
            </span>
            <br></br>
            <span>
              Dabei ist die Angabe von Name, Vorname, E-Mail-Adresse und ggf.
              Rückrufnummer
            </span>
            <br></br>
            <span>
              sowie eine kurze Skizzierung des Anliegens nötig, damit diese
              Anfrage von uns
            </span>
            <br></br>
            <span>beantwortet werden kann.</span>
            <br></br>
            <span>
              Die Verarbeitung der von Ihnen hinterlegten Kontaktdaten erfolgt
              allein auf Basis Ihrer
            </span>
            <br></br>
            <span>
              freiwilligen Einwilligung. Die von Ihnen im Formular hinterlegten
              personenbezogenen
            </span>
            <br></br>
            <span>
              Daten werden nach Erledigung der von Ihnen gestellten Anfrage
              gelöscht. Die
            </span>
            <br></br>
            <span>
              Übertragung der personenbezogenen Daten ist durch eine sogenannte
              SSL-/Let’s-
            </span>
            <br></br>
            <span>
              Encrypt-Verschlüsselung und das Übertragungsprotokoll https
              geschützt, mehr dazu
            </span>
            <br></br>
            <span>
              weiter unten in dieser Erklärung. Sie sind übrigens nicht
              verpflichtet,
            </span>
            <br></br>
            <span>
              personenbezogene Daten bereitzustellen. Diskretion gehört für uns
            </span>
            <br></br>
            <span>
              selbstverständlich dazu. Wenn Sie uns also personenbezogene Daten
              übermitteln,
            </span>
            <br></br>
            <span>
              handhaben wir diese selbstverständlich innerhalb der Richtlinien
              der DSGVO. Eine
            </span>
            <br></br>
            <span>
              Weitergabe Ihrer Daten an Dritte erfolgt dabei grundsätzlich nicht
              ohne Ihre
            </span>
            <br></br>
            <span>
              ausdrückliche Zustimmung. So können Ausnahmen lediglich im Rahmen
              einer
            </span>
            <br></br>
            <span>
              Geschäftsbeziehung oder eines Vertragsverhältnisses entstehen, wie
              z. B. die
            </span>
            <br></br>
            <span>
              Weitergabe von Daten an Lieferanten zur Erfüllung von durch sie
              autorisierten
            </span>
            <br></br>
            <span>
              Aufträgen. Aber auch hier aber verwenden die von Ihnen angegebenen
            </span>
            <br></br>
            <span>
              personenbezogenen Daten vertraulich und ausschließlich auf
              Einwilligung ihrerseits
            </span>
            <br></br>
            <span>
              sowie zweckbestimmt, etwa die Adressangabe für eine Anlieferung
              von
            </span>
            <br></br>
            <span>
              Erzeugnissen. Es ist Ihr gutes Recht, Auskunft zu evtl. über Sie
              gespeicherte Daten zu
            </span>
            <br></br>
            <span>
              bekommen, zudem haben Sie das Recht auf Korrektur, Sperrung und
              Löschung. Sie
            </span>
            <br></br>
            <span>
              haben das Recht, jede Einwilligungen zu widerrufen. Durch den
              Widerruf bleibt jedoch
            </span>
            <br></br>
            <span>
              die Rechtmäßigkeit der bis zum Widerruf erfolgten Verarbeitung
              unberührt. Widerrufe
            </span>
            <br></br>
            <span>
              können Sie uns z. B. per E-Mail übermitteln. Wenn Sie ansonsten in
              eines unserer
            </span>
            <br></br>
            <span>
              Formulare Daten eingegeben haben, willigen Sie ein, dass wir mit
              Ihnen zu dem oben
            </span>
            <br></br>
            <span>genannten Zweck Kontakt aufnehmen.</span>
          </span>
          <div className="datenschutz-container06"></div>
          <h3 className="datenschutz-text141">Cookies</h3>
          <span className="datenschutz-text142">
            <span>
              Technisch verwenden Internetseiten im Hintergrund sogenannte
              „Cookies“. Es
            </span>
            <br></br>
            <span>
              handelt sich hierbei um kleine Datenschnipsel, die der Seite dabei
              helfen, den
            </span>
            <br></br>
            <span>
              Benutzern das Surfen und die Nutzung der Seite zu beschleunigen
              und zu optimieren.
            </span>
            <br></br>
            <span>
              Die Technik verwendet während einer Sitzung hauptsächlich so
              genannte „Session-
            </span>
            <br></br>
            <span>
              Cookies“. Diese werden nach Ihrem Besuch automatisch gelöscht.
              Zusätzlich werden
            </span>
            <br></br>
            <span>
              ebenso zur Verbesserung der Benutzerfreundlichkeit temporäre
              Cookies eingesetzt.
            </span>
            <br></br>
            <span>
              Diese werden für einen bestimmten längeren Zeitraum, etwa eine
              Woche, auf dem
            </span>
            <br></br>
            <span>
              von Ihnen verwendeten Endgerät platziert. Wenn Sie unsere Seite in
              diesem Zeitraum
            </span>
            <br></br>
            <span>
              erneut besuchen, so erkennt die Seite, dass Sie schon mal auf der
              Seite waren. So
            </span>
            <br></br>
            <span>
              müssen bestimmte Inhalte nicht noch mal geladen und bestimmte
              Einstellungen
            </span>
            <br></br>
            <span>
              nicht nochmal vorgenommen werden, was die Nutzung der Seite
              erheblich erleichtert.
            </span>
            <br></br>
            <span>
              Sie können unsere Internetseite natürlich auch ohne Cookies
              besuchen. D. h. Sie
            </span>
            <br></br>
            <span>
              müssen dem Fenster auf der unserer Startseite „Cookies
              akzeptieren“ nicht
            </span>
            <br></br>
            <span>
              zustimmen. Da Cookies jedoch zur Erleichterung des Betriebs einer
              Webseite
            </span>
            <br></br>
            <span>
              beitragen, kann eine Ablehnung Funktionseinschränkungen nach sich
              ziehen. Die
            </span>
            <br></br>
            <span>
              Hilfe-Einrichtung Ihres Webbrowsers sagt Ihnen, was Sie ggf. über
              das Ablehnen von
            </span>
            <br></br>
            <span>
              Cookies wissen müssen und wie Sie ggf. schon gespeicherte Cookies
              löschen.
            </span>
            <br></br>
            <span>
              Darüber hinaus setzt die Seite Cookies ein, um die Nutzung der
              Seite statistisch zu
            </span>
            <br></br>
            <span>
              erfassen und für den Zweck einer Verbesserung des Seitenangebots
              auszuwerten.
            </span>
            <br></br>
            <span>
              Die hier erhobenen Daten sind jedoch nicht personalisiert.
            </span>
          </span>
          <div className="datenschutz-container07"></div>
          <h3 className="datenschutz-text182">Datensicherheit</h3>
          <span className="datenschutz-text183">
            <span>
              Um die Übertragungssicherheit Ihrer Daten bei der Eingabe in ein
              Formular zu
            </span>
            <br></br>
            <span>
              schützen, verwenden wir das dem aktuellen Stand der Technik
              entsprechende
            </span>
            <br></br>
            <span>
              Verschlüsselungsverfahren über HTTPS. Vorsorglich weisen wir Sie
              jedoch darauf
            </span>
            <br></br>
            <span>
              hin, dass eine Übermittlung von Daten im World Wide Web auch
              Sicherheitsrisiken
            </span>
            <br></br>
            <span>
              bieten kann. Ein vollkommen sicherer Schutz von Daten vor dem
              Zugriff von Hackern
            </span>
            <br></br>
            <span>
              oder Industriespionen ist Stand heute leider nicht in jedem Fall
              möglich.
            </span>
          </span>
          <div className="datenschutz-container08"></div>
          <h3 className="datenschutz-text195">Rechtliche Hinweise</h3>
          <span className="datenschutz-text196">
            <span>
              Alle Rechte vorbehalten. Sämtliche Texte, Illustrationen und Fotos
              sowie Bilder auf
            </span>
            <br></br>
            <span>
              unserer Internetseite unterliegen dem Copyright und sind eventuell
              durch Dritte
            </span>
            <br></br>
            <span>
              zusätzlich urheberrechtlich geschützt. Kein Auszug und Element
              unserer
            </span>
            <br></br>
            <span>
              Internetseite darf ohne unsere ausdrückliche schriftliche
              Genehmigung reproduziert
            </span>
            <br></br>
            <span>
              und digital oder in sonst einer Art verarbeitet, vervielfältigt
              und verbreitet werden. Wir
            </span>
            <br></br>
            <span>
              übernehmen dabei keine Haftung für die Datensicherheit der über
              die Website oder
            </span>
            <br></br>
            <span>
              per E-Mail eingesandten Informationen jeglicher Art. Die Infos auf
              unserer
            </span>
            <br></br>
            <span>
              Internetseite sind im Übrigen keine rechtverbindlichen oder
              faktischen
            </span>
            <br></br>
            <span>
              Zusicherungen. Wir behalten uns weiterhin alle Rechte vor,
              Änderungen, Löschungen
            </span>
            <br></br>
            <span>
              oder Ergänzungen der Internetseiten-Contents vorzunehmen. Aus oben
              und unten
            </span>
            <br></br>
            <span>
              Beschriebenem dürfen in keinem Fall Rechtsansprüche abgeleitet
              werden. Wir haften
            </span>
            <br></br>
            <span>
              weder für direkte noch indirekte Schäden, die durch die Nutzung
              unserer Contents
            </span>
            <br></br>
            <span>
              entstehen, die auf dieser Webseite oder eventuell verlinkter
              Social Media Auftritte zu
            </span>
            <br></br>
            <span>
              finden sind, z. B. Facebook etc. Rechte und Pflichten zwischen uns
              und dem Nutzer
            </span>
            <br></br>
            <span>der Webseite oder Dritten bestehen nicht.</span>
          </span>
          <div className="datenschutz-container09"></div>
          <h3 className="datenschutz-text226">Haftungsbeschränkung</h3>
          <span className="datenschutz-text227">
            <span>
              Der Content unserer Internetseite ist mit maximaler Sorgfalt
              erstellt worden. Als
            </span>
            <br></br>
            <span>
              Anbieter können wir jedoch leider keine Gewährleistung für die
              vollständige
            </span>
            <br></br>
            <span>
              Korrektheit und Aktualität der zur Verfügung gestellten Contents
              übernehmen. Die
            </span>
            <br></br>
            <span>
              Benutzung der Contents dieser Internetseite erfolgt auf Ihre
              eigene Gefahr.
            </span>
            <br></br>
            <span>
              Gekennzeichnete Textbausteine und Zitate spiegeln die Meinung des
              zitierten
            </span>
            <br></br>
            <span>
              Urhebers der Quelle und nicht unbedingt unsere Meinung wider.
            </span>
          </span>
          <div className="datenschutz-container10"></div>
          <h3 className="datenschutz-text239">Verlinkung von Fremdangeboten</h3>
          <span className="datenschutz-text240">
            <span>
              Im Rahmen unserer Internetpräsenz verlinken wir hin und wieder
              über unser eigenes
            </span>
            <br></br>
            <span>
              Online-Angebot hinaus auch auf Dienste von Fremdanbietern, wenn
              dies den
            </span>
            <br></br>
            <span>
              Nutzwert für den User steigert, etwa zu YouTube (Videos) etc. Die
              Nutzung dieser
            </span>
            <br></br>
            <span>
              Dienste zieht es nach sich, dass Ihre IP-Adresse an den
              Fremdanbieter transferiert
            </span>
            <br></br>
            <span>
              wird, damit Sie in den Genuss dieser Inhalte kommen. Obwohl wir
              hierbei den
            </span>
            <br></br>
            <span>
              Fremdanbieter prüfen, haben wir keinen Einfluss darauf, ob die
              jeweiligen
            </span>
            <br></br>
            <span>
              Fremdanbieter die Daten wirklich nur kurzfristig verwenden. Bitte
              lesen Sie im Zweifel
            </span>
            <br></br>
            <span>
              deren Datenschutzerklärungen, bevor Sie den Links folgen.
            </span>
          </span>
          <div className="datenschutz-container11"></div>
          <h3 className="datenschutz-text256">Links auf Soziale Netzwerke</h3>
          <span className="datenschutz-text257">
            <span>
              Wir verlinken auf einige soziale Netzwerke und weisen hiermit
              darauf hin, um unserer
            </span>
            <br></br>
            <span>
              Hinweispflicht nachzukommen. Auf deren Umgang mit Ihren Daten
              haben wir jedoch
            </span>
            <br></br>
            <span>keinen Einfluss.</span>
          </span>
          <div className="datenschutz-container12"></div>
          <h3 className="datenschutz-text263">Facebook</h3>
          <span className="datenschutz-text264">
            <span>
              Wenn Sie unseren Facebook-Button aktivieren, wird eine Verbindung
              zu den
            </span>
            <br></br>
            <span>
              Facebook-Servern hergestellt und dabei das dargestellte
              Facebook-Plugin, z. B. „Like-
            </span>
            <br></br>
            <span>
              Button“, durch Mitteilung an Ihren Browser auf der jeweiligen
              Internetseite
            </span>
            <br></br>
            <span>
              nachgeladen. Hierdurch wird an den Facebook-Server in den USA
              übermittelt, welche
            </span>
            <br></br>
            <span>
              unserer Internetseiten Sie besucht haben. Sind Sie dabei als
              Mitglied bei Facebook
            </span>
            <br></br>
            <span>
              eingeloggt, ordnet Facebook diese Information Ihrem persönlichen
              Facebook-
            </span>
            <br></br>
            <span>
              Benutzerkonto zu. Bei der Nutzung der Plugin-Funktionen (z. B.
              Anklicken des „Like-
            </span>
            <br></br>
            <span>
              Buttons“, Abgabe eines Kommentars) werden auch diese Informationen
              Ihrem
            </span>
            <br></br>
            <span>
              Facebook-Konto zugeordnet, was Sie nur durch Ausloggen vor Nutzung
              des Plugins
            </span>
            <br></br>
            <span>
              verhindern können. Die aktive Nutzung von Facebook Plugins
              unterliegt den
            </span>
            <br></br>
            <span>
              Datenschutzbedingungen und Nutzungsbedingungen der Facebook Inc.
            </span>
            <br></br>
            <span>
              Informationen zur Erhebung, Speicherung und Nutzung Ihrer Daten
              durch die
            </span>
            <br></br>
            <span>
              Facebook Inc. finden Sie in den Facebook Datenschutzrichtlinien
              (http://de-
            </span>
            <br></br>
            <span>
              de.facebook.com/policy/) und dem Leitfaden zur Privatsphäre.
            </span>
          </span>
          <div className="datenschutz-container13"></div>
          <h3 className="datenschutz-text292">Twitter</h3>
          <span className="datenschutz-text293">
            <span>
              Wenn Sie unseren Twitter-Button aktivieren, wird eine Verbindung
              mit
            </span>
            <br></br>
            <span>
              www.twitter.com hergestellt und dabei das Twitter-Plugin, der.
              Eigentliche Twitter-
            </span>
            <br></br>
            <span>
              Button durch Mitteilung an Ihren Browser auf der jeweiligen
              Internetseite
            </span>
            <br></br>
            <span>
              nachgeladen. Sofern Sie sich bei Twitter eingeloggt haben, wird
              auf Ihrem Twitter-
            </span>
            <br></br>
            <span>
              Account in einem sog. Tweet auf unsere Website verwiesen. Auch
              hier werden die
            </span>
            <br></br>
            <span>
              entsprechenden Informationen direkt von dem Plugin an Twitter in
              den USA
            </span>
            <br></br>
            <span>
              übermittelt und allen Dritten sichtbar gemacht, die Ihre Tweets
              lesen können. Wenn
            </span>
            <br></br>
            <span>
              Sie mehr über den Zweck und den Umgang mit Ihren Daten und deren
              weitere
            </span>
            <br></br>
            <span>Verwendung durch Twitter erfahren möchten, besuchen Sie</span>
            <br></br>
            <span>
              bitte http://twitter.com/privacy. Weitere Einzelheiten über den
              Tweet-Button erhalten 
            </span>
            <br></br>
            <span>Sie auch unter </span>
            <br></br>
            <span>http://twitter.com/about/</span>
            <span>resources/tweetbutton </span>
            <br></br>
            <span>(Quellen)</span>
            <br></br>
            <span>und http://dev.twitter.com/</span>
            <span>pages/</span>
            <span>tweet_button_faq</span>
            <br></br>
            <span> (Tweet Button FAQ).</span>
            <br></br>
            <span>
              Wir behalten uns vor, die
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br></br>
            <span>
              Datenschutzerklärung wenn nötig anzupassen, damit diese 
            </span>
            <span>
              den
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br></br>
            <span>
              aktuellen rechtlichen Anforderungen genügt. Für Ihren Besuch auf
              unserer
            </span>
            <br></br>
            <span>Website gilt jeweils die aktuelle Fassung.</span>
          </span>
          <div className="datenschutz-container14"></div>
          <h3 className="datenschutz-text335">Fragen zum Datenschutz</h3>
          <span className="datenschutz-text336">
            <span>
              Bei Fragen rund um das Thema Datenschutz, kontaktieren Sie uns
              oder Sie richten
            </span>
            <br></br>
            <span>Ihre Anfrage an unseren Datenschutzbeauftragten:</span>
            <br></br>
          </span>
          <span className="datenschutz-text341">Jenny Helber</span>
          <span className="datenschutz-text342">
            E-Mail: <a href="mailto:%68%65%6c%62%65%72%40%72%65%6e%61%74%75%72%65%70%65%6f%70%6c%65%2e%64%65">helber@renaturepeople.de</a>
          </span>
        </div>
      </div>
      <Footer rootClassName="footer-root-class-name4"></Footer>
    </div>
  )
}

export default Datenschutz
