import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Footer from '../components/footer'
import './cofymi.css'

const Cofymi = (props) => {
  return (
    <div className="cofymi-container">
      <Helmet>
        <title>
          Cofymi - reNature People: Naturschutz & Nachhaltigkeit für Mensch und
          Natur
        </title>
        <meta
          name="description"
          content="Willkommen bei reNature People - der gemeinnützigen Non-Profit Organisation für Naturschutz und harmonisches Miteinander von Mensch und Natur. Schütze die Natur, unterstütze unsere Projekte weltweit."
        />
        <meta
          property="og:title"
          content="Cofymi - reNature People: Naturschutz &amp; Nachhaltigkeit für Mensch und Natur"
        />
        <meta
          property="og:description"
          content="Eine seit 2021 arbeitende gemeinnützige Non-Profit Organisation, die sich dem Ziel des Naturschutzes und der Förderung eines harmonischen Miteinanders von Mensch und Natur verschrieben hat."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/97781406-fd67-48a1-b9e9-35531dc96503/26880b92-dc51-45d7-8076-8acf5157488d?org_if_sml=1"
        />
      </Helmet>
      <div className="cofymi-image">
        <Header></Header>
        <div className="cofymi-bg"></div>
      </div>
      <div className="cofymi-container1">
        <div className="cofymi-container2">
          <img alt="image" src="/cy2-200h.png" className="cofymi-image1" />
        </div>
        <div className="cofymi-container3">
          <h3 className="cofymi-text">
            <span>Fynn, Corny und Mimi</span>
            <br></br>
          </h3>
          <span className="cofymi-text03">
            <span>
              „Wir von COFYMI glauben fest daran, dass es einen großen
              Unterschied macht, wie 
            </span>
            <span>
              die Kaffeebohnen geerntet, verarbeitet und vermarktet werden. Dass
              es einen großen 
            </span>
            <span>
              Unterschied macht, ob Liebe, Leidenschaft und auch Nächstenliebe
              im Produkt 
            </span>
            <span>
              stecken. Denn für uns ist Kaffee nicht gleich Kaffee. Für uns ist
              Kaffee ein 
            </span>
            <span>Lebensgefühl und das sollte ein gutes Gefühl sein! </span>
            <br></br>
            <span>
              Hilfe zur Selbsthilfe ist uns ein großes Anliegen, deshalb
              unterstützen wir als „Helping 
            </span>
            <span>Hands“ reNature People sehr gerne!“</span>
          </span>
          <a
            href="https://cofymi.com/"
            target="_blank"
            rel="noreferrer noopener"
            className="cofymi-text12"
          >
            Zur Webseite
          </a>
        </div>
      </div>
      <Footer rootClassName="footer-root-class-name5"></Footer>
    </div>
  )
}

export default Cofymi
