import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Footer from '../components/footer'
import './impressum.css'

const Impressum = (props) => {
  return (
    <div className="impressum-container">
      <Helmet>
        <title>
          Impressum - reNature People: Naturschutz & Nachhaltigkeit für Mensch
          und Natur
        </title>
        <meta
          name="description"
          content="Willkommen bei reNature People - der gemeinnützigen Non-Profit Organisation für Naturschutz und harmonisches Miteinander von Mensch und Natur. Schütze die Natur, unterstütze unsere Projekte weltweit."
        />
        <meta
          property="og:title"
          content="Impressum - reNature People: Naturschutz &amp; Nachhaltigkeit für Mensch und Natur"
        />
        <meta
          property="og:description"
          content="Eine seit 2021 arbeitende gemeinnützige Non-Profit Organisation, die sich dem Ziel des Naturschutzes und der Förderung eines harmonischen Miteinanders von Mensch und Natur verschrieben hat."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/97781406-fd67-48a1-b9e9-35531dc96503/26880b92-dc51-45d7-8076-8acf5157488d?org_if_sml=1"
        />
      </Helmet>
      <div className="impressum-image">
        <Header></Header>
        <div className="impressum-bg"></div>
      </div>
      <div className="impressum-container01">
        <div className="impressum-container02">
          <h3 className="impressum-text">Impressum</h3>
          <div className="impressum-container03">
            <div className="impressum-container04">
              <h4 className="impressum-text001">Angaben gemäß § 5 TMG:</h4>
              <span className="impressum-text002">
                <span>reNature People gGmbH</span>
                <br></br>
                <span>Hasenweg 5</span>
                <br></br>
                <span>D – 73099 Adelberg</span>
                <br></br>
                <span>USt-IdNr.: DE348529975</span>
              </span>
              <h4 className="impressum-text010">Kontakt:</h4>
              <span className="impressum-text011">
                <span>E-Mail: info@renaturepeople.de</span>
                <br></br>
              </span>
            </div>
            <div className="impressum-container05">
              <h4 className="impressum-text014">
                <span>Verantwortlich für den Inhalt</span>
                <br></br>
                <span> nach §§ 55 Abs. 2 RStV:</span>
              </h4>
              <span className="impressum-text018">
                <span>Ahmet Papila</span>
                <br></br>
                <span>E-Mail: info@renaturepeople.de</span>
                <br></br>
                <br></br>
              </span>
              <h4 className="impressum-text024">Vertreten durch:</h4>
              <span className="impressum-text025">
                Ahmet Papila, Geschäftsleitung
              </span>
            </div>
          </div>
          <div className="impressum-container06"></div>
          <h3 className="impressum-text026">Haftung für die Inhalte</h3>
          <span className="impressum-text027">
            <span>
              Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt.
              Für die Richtigkeit,
            </span>
            <br></br>
            <span>
              Vollständigkeit und Aktualität der Inhalte können wir jedoch keine
              Gewähr übernehmen. Als
            </span>
            <br></br>
            <span>
              Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
              auf diesen Seiten nach den
            </span>
            <br></br>
            <span>
              allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir
              als Diensteanbieter
            </span>
            <br></br>
            <span>
              jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde
              Informationen zu
            </span>
            <br></br>
            <span>
              überwachen oder nach Umständen zu forschen, die auf eine
              rechtswidrige Tätigkeit
            </span>
            <br></br>
            <span>
              hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
              Nutzung von Informationen
            </span>
            <br></br>
            <span>
              nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine
              diesbezügliche Haftung ist
            </span>
            <br></br>
            <span>
              jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
              Rechtsverletzung möglich. Bei
            </span>
            <br></br>
            <span>
              Bekanntwerden von entsprechenden Rechtsverletzungen werden wir
              diese Inhalte
            </span>
            <br></br>
            <span>umgehend entfernen.</span>
          </span>
          <div className="impressum-container07"></div>
          <h3 className="impressum-text049">Haftung für Links</h3>
          <span className="impressum-text050">
            <span>
              Unser Angebot enthält Links zu externen Webseiten Dritter, auf
              deren Inhalte wir keinen
            </span>
            <br></br>
            <span>
              Einfluss haben. Deshalb können wir für diese fremden Inhalte auch
              keine Gewähr
            </span>
            <br></br>
            <span>
              übernehmen. Für die Inhalte der verlinkten Seiten ist stets der
              jeweilige Anbieter oder
            </span>
            <br></br>
            <span>
              Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden
              zum Zeitpunkt der
            </span>
            <br></br>
            <span>
              Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
              Inhalte waren zum
            </span>
            <br></br>
            <span>
              Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente
              inhaltliche Kontrolle der
            </span>
            <br></br>
            <span>
              verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
              Rechtsverletzung nicht
            </span>
            <br></br>
            <span>
              zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
              derartige Links
            </span>
            <br></br>
            <span>umgehend entfernen.</span>
          </span>
          <div className="impressum-container08"></div>
          <h3 className="impressum-text068">Urheberrecht</h3>
          <span className="impressum-text069">
            <span>
              Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
              diesen Seiten unterliegen
            </span>
            <br></br>
            <span>
              dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung,
              Verbreitung und jede Art
            </span>
            <br></br>
            <span>
              der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen
              der schriftlichen
            </span>
            <br></br>
            <span>
              Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und
              Kopien dieser Seite sind
            </span>
            <br></br>
            <span>
              nur für den privaten, nicht kommerziellen Gebrauch gestattet.
              Soweit die Inhalte auf dieser
            </span>
            <br></br>
            <span>
              Seite nicht vom Betreiber erstellt wurden, werden die
              Urheberrechte Dritter beachtet.
            </span>
            <br></br>
            <span>
              Insbesondere werden Inhalte Dritter als solche gekennzeichnet.
              Sollten Sie trotzdem auf
            </span>
            <br></br>
            <span>
              eine Urheberrechtsverletzung aufmerksam werden, bitten wir um
              einen entsprechenden
            </span>
            <br></br>
            <span>
              Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir
              derartige Inhalte
            </span>
            <br></br>
            <span>umgehend entfernen.</span>
          </span>
          <div className="impressum-container09"></div>
          <h3 className="impressum-text089">
            <span>Datenschutz</span>
            <br></br>
          </h3>
          <span className="impressum-text092">
            <span>
              Die Nutzung unserer Webseite ist in der Regel ohne Angabe
              personenbezogener Daten
            </span>
            <br></br>
            <span>
              möglich. Soweit auf unseren Seiten personenbezogene Daten
              (beispielsweise Name,
            </span>
            <br></br>
            <span>
              Anschrift oder eMail-Adressen) erhoben werden, erfolgt dies,
              soweit möglich, stets auf
            </span>
            <br></br>
            <span>
              freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche
              Zustimmung nicht an Dritte
            </span>
            <br></br>
            <span>weitergegeben.</span>
            <br></br>
            <span>
              Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B.
              bei der Kommunikation
            </span>
            <br></br>
            <span>
              per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser
              Schutz der Daten vor dem
            </span>
            <br></br>
            <span>Zugriff durch Dritte ist nicht möglich.</span>
            <br></br>
            <span>
              Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
              Kontaktdaten durch
            </span>
            <br></br>
            <span>
              Dritte zur Übersendung von nicht ausdrücklich angeforderter
              Werbung und
            </span>
            <br></br>
            <span>
              Informationsmaterialien wird hiermit ausdrücklich widersprochen.
            </span>
            <br></br>
            <span>
              Die Betreiber der Seiten behalten sich ausdrücklich rechtliche
              Schritte im Falle der
            </span>
            <br></br>
            <span>
              unverlangten Zusendung von Werbeinformationen, etwa durch
              Spam-Mails, vor.
            </span>
          </span>
          <div className="impressum-container10"></div>
          <h3 className="impressum-text118">Auszug aus unserer Satzung:</h3>
          <span className="impressum-text119">
            <span>§1</span>
            <br></br>
            <span>
              Zweck der reNature People gGmbH ist die Wiederherstellung und
              Bewahrung der natürlichen
            </span>
            <br></br>
            <span>
              Lebensgrundlagen, die Förderung des Natur-, Tier- und
              Umweltschutzes einschließlich der Bildungs-
            </span>
            <br></br>
            <span>
              und Forschungsarbeit in den genannten Bereichen und die Förderung
              der
            </span>
            <br></br>
            <span>Entwicklungszusammenarbeit und Entwicklungshilfe.</span>
            <br></br>
            <br></br>
            <span>Der Satzungszweck wird verwirklicht insbesondere durch:</span>
            <br></br>
            <br></br>
            <span>
              (a) Erhalten, Schaffen und Verbessern von Lebensgrundlagen durch
              den Aufbau nachhaltiger
            </span>
            <br></br>
            <span>
              Strukturen für eine artenreiche Tier- und Pflanzenwelt (z.B. durch
              Landschaftspflege, Anpflanzungen)
            </span>
            <br></br>
            <span>
              und Unterstützung bei der Sicherung der Lebensgrundlage für
              Menschen im Bereich einer
            </span>
            <br></br>
            <span>
              nachhaltigen Landnutzung (z.B. durch Wissensvermittlung, Aus- und
              Fortbildung, durch
            </span>
            <br></br>
            <span>
              Unterstützung beim Schaffen von Erwerbstätigkeit in den im Zweck
              genannten Bereichen in
            </span>
            <br></br>
            <span>
              Entwicklungsländern – hier insbesondere verwirklicht durch die
              Hilfe zur Selbsthilfe durch
            </span>
            <br></br>
            <span>
              Zurverfügungstellung von Land, der Ausbildung im Bereich
              nachhaltiger Landnutzung durch
            </span>
            <br></br>
            <span>
              Wiederaufforstung und ähnlicher Prozesse und die pädagogische und
              technische Begleitung der
            </span>
            <br></br>
            <span>nachhaltigen Landnutzung)</span>
            <br></br>
            <br></br>
            <span>
              (b) Medien- und Öffentlichkeitsarbeit im Natur- und Umweltschutz
              (z.B. Presseartikel,
            </span>
            <br></br>
            <span>
              Veröffentlichungen, Ausstellungen, Informationsveranstaltungen,
              Präsentationen)
            </span>
            <br className="impressum-text156"></br>
            <br></br>
            <span>
              (c) Naturpädagogik (z.B. Seminare, Workshops, Schulungen,
              Mitmachaktionen, Führungen,
            </span>
            <br></br>
            <span>Beratungen)</span>
            <br className="impressum-text161"></br>
            <br></br>
            <span>
              (d) Erforschung und deren Förderung im Bereich Natur- und
              Umweltschutz (z.B. Studien,
            </span>
            <br></br>
            <span>Kartierungen)</span>
            <br className="impressum-text166"></br>
            <br></br>
            <span>
              (e) Ein- und Mitwirken auf und bei Planungen und Umsetzungen, die
              die Belange des Natur- und
            </span>
            <br></br>
            <span>Umweltschutzes tangieren</span>
          </span>
        </div>
      </div>
      <Footer rootClassName="footer-root-class-name"></Footer>
    </div>
  )
}

export default Impressum
