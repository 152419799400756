import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Footer from '../components/footer'
import './spenden.css'

const Spenden = (props) => {
  return (
    <div className="spenden-container">
      <Helmet>
        <title>
          Spenden - reNature People: Naturschutz & Nachhaltigkeit für Mensch und
          Natur
        </title>
        <meta
          name="description"
          content="Unterstützen Sie Artenvielfalt und Naturschutz mit Ihrer Spende. Helfen Sie uns, artenreiche Flächen zu erhalten und aufzubauen sowie für ökologische Zusammenhänge zu sensibilisieren. Gemeinnützige Organisation. Steuerwirksame Spendenbescheinigung auf Anfrage."
        />
        <meta
          property="og:title"
          content="Spenden - reNature People: Naturschutz &amp; Nachhaltigkeit für Mensch und Natur"
        />
        <meta
          property="og:description"
          content="Unterstützen Sie Artenvielfalt und Naturschutz mit Ihrer Spende. Helfen Sie uns, artenreiche Flächen zu erhalten und aufzubauen sowie für ökologische Zusammenhänge zu sensibilisieren. Gemeinnützige Organisation. Steuerwirksame Spendenbescheinigung auf Anfrage."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/97781406-fd67-48a1-b9e9-35531dc96503/26880b92-dc51-45d7-8076-8acf5157488d?org_if_sml=1"
        />
      </Helmet>
      <div className="spenden-hero">
        <Header rootClassName="header-root-class-name12"></Header>
        <div className="spenden-bg"></div>
      </div>
      <div className="spenden-post-details">
        <h3 className="spenden-text">
          Ihre Spende für Artenvielfalt und Naturschutz
        </h3>
        <span className="spenden-text01">
          <span>
            Unterstützen Sie mit einer Spende unsere Renaturierungsprojekte im
            In- und Ausland. Sie leisten mit
          </span>
          <br></br>
          <span>
            Ihrer Spende einen ganz konkreten Beitrag zum Erhalt und
            Wiederaufbau artenreicher Flächen sowie von
          </span>
          <br></br>
          <span>
            Bildungsangeboten zur Sensibilisierung für ökologische Zusammenhänge
            und Naturschutz!
          </span>
          <br></br>
          <span>
            Wir sind als gemeinnützige Organisation anerkannt und stellen Ihnen
            auf Wunsch eine steuerwirksame
          </span>
          <br></br>
          <span>Spendenbescheinigung aus.</span>
        </span>
      </div>
      <div className="spenden-container1">
        <div className="spenden-story">
          <div className="spenden-container2">
            <div className="spenden-container3">
              <h1 className="spenden-text11">
                Wofür verwenden wir Ihre Spenden?
              </h1>
              <span className="spenden-text12">
                <span className="spenden-text13">
                  1. Renaturierung und Biotopvernetzung in Deutschland
                </span>
                <br></br>
                <span>
                  Wir regenerieren und pflegen verarmte oder versiegelte
                  Freiflächen und Gärten und entwickeln so in Deutschland kleine
                  Biotope, in denen sich wieder eine reichhaltige 
                </span>
                <span>
                  Artenvielfalt entwickeln kann (Stichwort: Biotopvernetzung).
                  Wir beraten Garten- und Freiflächenbesitzer und erstellen
                  Konzepte, wie diese Flächen wieder in einen lebendigen 
                </span>
                <span>
                  Zustand zurückgeführt werden können. Wir schaffen
                  Bildungsveranstaltungen um für die ökologischen Zusammenhänge
                  und Naturschutz zu sensibilisieren.
                </span>
                <br className="spenden-text18"></br>
                <br></br>
                <span className="spenden-text20">2. Projekte im Regenwald</span>
                <br></br>
                <span>
                  Mit ihrer Spende kaufen wir Regenwaldflächen. Bei Flächen, die
                  bereits durch den Einfluss des Menschen in Mitleidenschaft
                  gezogen wurden, forsten wir diese Flächen so auf, dass sich
                  wieder ein artenreicher Pflanzen- und Tierbestand bilden kann.
                  Zu diesem Zweck betreiben 
                </span>
                <span>
                  wir Baumschulen vor Ort. Wir überlassen diese Flächen später
                  teilweise der indigenen Landbevölkerung, dass diese hier eine
                  neue Lebensgrundlage finden kann. Darüber hinaus unterstützen
                  wir die dort lebenden Menschen bei der Vermarktung ihrer
                  Produkte, indem wir Kooperationen bilden und die direkte
                  Vermarktung fördern.
                </span>
              </span>
            </div>
            <div className="spenden-container4">
              <h1 className="spenden-text24">Ihre Spende</h1>
              <span className="spenden-text25">
                <span>
                  Wofür wollen sie konkret spenden? Bitte geben sie die dies auf
                  Ihrer Überweisung mit an:
                </span>
                <br className="spenden-text27"></br>
                <br></br>
                <span>1. Spende für Deutschland</span>
                <br></br>
                <span>2. Spende für den Regenwald</span>
                <br></br>
                <span>
                  3. Freie Spende (wir setzen das Geld dort ein, wo es am
                  dringendsten benötigt wird.)
                </span>
                <br className="spenden-text34"></br>
                <br></br>
                <span className="spenden-text36">
                  Wie gehe ich vor, wenn ich mich für eine Spende entschieden
                  haben?
                </span>
                <br></br>
                <span>
                  1. Überweisen Sie mit einem der oben beschriebenen
                  Verwendungszwecke
                </span>
                <br></br>
                <span>
                  2. Übermitteln Sie uns Ihre Kontaktdaten, entweder per Mail
                  oder auf dem Überweisungsträger
                </span>
                <br></br>
                <span>
                  3. Sie erhalten von uns ein Dankschreiben und auf Wunsch eine
                  Spendenbescheinigung.
                </span>
                <br></br>
                <br></br>
                <span className="spenden-text45">Spendenkonto</span>
                <br></br>
                <span>Empfänger: reNature People gGmbH</span>
                <br></br>
                <span>IBAN: DE97 6105 0000 0049 1227 36</span>
                <br></br>
                <span>BIC: GOPSDE6GXXX</span>
                <br></br>
                <span>Kreissparkasse Göppingen</span>
                <br></br>
              </span>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Spenden
