import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import './style.css'
import Websiteby from './views/websiteby'
import FriedrichUndPartner from './views/friedrich-und-partner'
import Leistungen from './views/leistungen'
import Vision from './views/vision'
import Home from './views/home'
import Impressum from './views/impressum'
import Cofymi from './views/cofymi'
import Spenden from './views/spenden'
import Projekte from './views/projekte'
import Datenschutz from './views/datenschutz'

const App = () => {
  return (
    <Router>
      <Routes>
          <Route element={<Websiteby />}  path="/websiteby" />
          <Route element={<FriedrichUndPartner />}  path="/friedrich-und-partner"/>
          <Route element={<Leistungen />}  path="/leistungen" />
          <Route element={<Vision />}  path="/vision" />
          <Route element={<Home />} index />
          <Route element={<Impressum />}  path="/impressum" />
          <Route element={<Cofymi />}  path="/cofymi" />
          <Route element={<Spenden />}  path="/spenden" />
          <Route element={<Projekte />}  path="/projekte" />
          <Route element={<Datenschutz />}  path="/datenschutz" />
        </ Routes>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))

