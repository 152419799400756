import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Footer from '../components/footer'
import './projekte.css'

const Projekte = (props) => {
  return (
    <div className="projekte-container">
      <Helmet>
        <title>
          Projekte - reNature People: Naturschutz & Nachhaltigkeit für Mensch
          und Natur
        </title>
        <meta
          name="description"
          content="Schützen und renaturieren Sie Naturräume weltweit. Erfahren Sie mehr über unsere Regenwald-Wiederaufforstung in Ecuador und Unterstützung der indigenen Bevölkerung. Gemeinsam bewahren wir die biologische Vielfalt."
        />
        <meta
          property="og:title"
          content="Projekte - reNature People: Naturschutz &amp; Nachhaltigkeit für Mensch und Natur"
        />
        <meta
          property="og:description"
          content="Schützen und renaturieren Sie Naturräume weltweit. Erfahren Sie mehr über unsere Regenwald-Wiederaufforstung in Ecuador und Unterstützung der indigenen Bevölkerung. Gemeinsam bewahren wir die biologische Vielfalt."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/97781406-fd67-48a1-b9e9-35531dc96503/26880b92-dc51-45d7-8076-8acf5157488d?org_if_sml=1"
        />
      </Helmet>
      <div className="projekte-hero">
        <Header rootClassName="header-root-class-name10"></Header>
        <div className="projekte-bg"></div>
      </div>
      <div className="projekte-post-details">
        <h3 className="projekte-text">Internationale Projekte</h3>
        <span className="projekte-text01">
          In dem Organismus der Erde hängt alles mit allem zusammen. Deshalb ist
          es unser Anliegen, überall dort tätig zu werden, wo wir wertvolle
          Naturräume schützen oder bereits vom Menschen zerstörte Naturräume
          wieder neu aufbauen können. Mit unseren internationalen Partnern
          entwickeln wir Konzepte nachhaltiger Waldwirtschaft und dem Aufbau von
          natürlichen Lebensräumen für Mensch und Natur. Unser Anliegen ist das
          Wohl der Natur und der Menschen, die von ihr leben!
        </span>
      </div>
      <div className="projekte-container1">
        <div className="projekte-story">
          <div className="projekte-container2">
            <div className="projekte-container3">
              <h1 className="projekte-text02 TextXL">
                Wiederaufforstung von Regenwald in Ecuador
              </h1>
              <span className="projekte-text03">
                <span>
                  Ausgangspunkt unseres Engagements im tropischen Regenwald von
                  Ecuador war der Aufenthalt von 
                </span>
                <span>
                  zwei Mitgründern der reNature People gGmbH im Jahr 2018.
                  Hierbei gewannen sie einen Einblick in fatale Missstände mit
                  negativen Auswirkungen, sowohl für die Natur als auch für die
                  überwiegend indigene Landbevölkerung. In Folge mangelnder
                  Kenntnisse der natürlichen Zusammenhänge als auch getrieben
                  von hohen Gewinnaussichten wurden mit viel Kapital in Ecuador
                  bis 2018 von zahlreichen Grundbesitzern 
                </span>
                <span>
                  bereits über 300.000 Hektar natürlicher Regenwald zur
                  Schaffung von Ölpalm-Monokulturen gerodet. Die so geschaffenen
                  Plantagen tragen einen wesentlichen Anteil zur Bodenerosion
                  bei und veröden und vergiften durch Überdüngung und Einsatz
                  von Herbiziden und Pestiziden den Boden und das Grundwasser.
                  Durch einen neuartigen Virus (Pudricion de cogollo), der
                  speziell die afrikanische Ölpalme befällt, gingen jedoch
                  bereits bis 2021 mehr als 50% der Plantagen ein. Die Palmen
                  starben einfach ab und bis heute gibt es kein Mittel, dies zu
                  verhindern. Die wirtschaftlichen Folgen für die Investoren und
                  Landbesitzer sind enorm, da das Investment für die Plantagen
                  hoch und eine Rendite nach frühestens 6 Jahren absehbar war.
                  Viele der teilweise hoch verschuldeten Plantagenbesitzer
                  müssen ihren Grund nun zur Begleichung ihrer Schulden
                  abstoßen. Aber auch für die einfache, hauptsächlich indigene
                  Landbevölkerung hatte dieser Umstand erhebliche negative
                  Auswirkungen, da sie durch das Plantagensterben ihr Einkommen
                  verloren, mit 
                </span>
                <span>
                  der Folge einer zunehmenden Verarmung und Abwanderung in die
                  Ballungsgebiete.
                </span>
              </span>
            </div>
            <div className="projekte-container4"></div>
          </div>
          <div className="projekte-container5">
            <div className="projekte-container6"></div>
            <div className="projekte-container7">
              <h1 className="projekte-text08">
                <span className="TextXL">Unser Engagement vor Ort</span>
                <br className="TextXL"></br>
              </h1>
              <span className="projekte-text11">
                <span className="projekte-text12">
                  In aller Kürze dargestellt werden wir derart abgestorbene
                  Plantagen günstig erwerben, bevor sie anderen
                  Monokultur-Invests wie beispielsweise dem Anbau von
                  Balsaholz-Monokulturen für Windkraftanlagen zugeführt werden,
                  und mit Hilfe von Partnern vor Ort zu renaturieren. Das
                  bedeutet, dass wir sie mit vielfältigen neuen Baumsetzlingen
                  zu bepflanzen werden, so dass wieder ein natürlicher
                  Sekundärwald mit hoher Diversität entsteht. Wir stellen den
                  ursprünglichen Regenwald mit einem dafür von unseren Experten
                  entwickelten Bepflanzungskonzept nach. Zu diesem Zweck
                  betreiben wir bereits auch Baumschulen in Ecuador, in denen
                  die vielfältige Pflanzenwelt des Regenwaldes nachgezüchtet
                  wird. Dieser Sekundärwald kann nun Lebensgrundlage der
                  heimischen Fauna und Flora werden. Damit erschaffen wir
                  ökologische Systeme, die zuvor durch das Handeln der Menschen
                  zerstört wurden und wirken der Bodendegradation und -erosion
                  nachhaltig entgegen. Dadurch wird die ursprüngliche
                  biologische Vielfalt und das Ökosystem Regenwald nachhaltig
                  wieder aufgebaut und langfristig geschützt. Die indigene
                  Bevölkerung Ecuadors lebt seit jeher von den reichen Gaben des
                  Regenwaldes. Durch die großflächige Zerstörung der Regenwälder
                  zum Zweck der Landgewinnung für Monokulturen wurden diese
                  Menschen immer mehr aus ihren natürlichen Lebensbedingungen
                  gerissen und in Lohnarbeit gedrängt. Durch das
                  Plantagensterben verloren viele von ihnen auch noch diese 
                </span>
                <span className="projekte-text13">
                  Lebensgrundlage. Nicht wenige sehen sich nun gezwungen, in die
                  Ballungsräume abzuwandern und ein Leben unter teils unwürdigen
                  Verhältnissen zu fristen. Unsere renaturierten Waldflächen
                  sollen der indigenen Bevölkerung wieder als Lebensraum dienen
                  und ein Ort lebendiger Kultur werden. 
                </span>
                <span className="projekte-text14">
                  Durch die Bildung von Kooperativen und der Unterstützung bei
                  der Schaffung von Marktzugängen unterstützen wir die Menschen,
                  wieder ein freies, selbstbestimmtes Leben im Einklang mit der
                  Natur und ihrer kulturellen Wurzeln führen zu können. Ziel ist
                  es hierbei, eine nachhaltige und schonende Waldbewirtschaftung
                  zu befördern. Um die Menschen vor Ort dahingehend zu
                  unterstützen, die nachhaltig gewonnen Gaben der Natur in
                  fairer und direkter Weise zu vermarkten, schaffen, vermitteln
                  wir Kontakte und schaffen zudem noch entsprechende
                  Bildungsangebote.
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Projekte
