import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Footer from '../components/footer'
import './friedrich-und-partner.css'

const FriedrichUndPartner = (props) => {
  return (
    <div className="friedrich-und-partner-container">
      <Helmet>
        <title>
          FriedrichUndPartner - reNature People: Naturschutz & Nachhaltigkeit
          für Mensch und Natur
        </title>
        <meta
          name="description"
          content="Willkommen bei reNature People - der gemeinnützigen Non-Profit Organisation für Naturschutz und harmonisches Miteinander von Mensch und Natur. Schütze die Natur, unterstütze unsere Projekte weltweit."
        />
        <meta
          property="og:title"
          content="FriedrichUndPartner - reNature People: Naturschutz &amp; Nachhaltigkeit für Mensch und Natur"
        />
        <meta
          property="og:description"
          content="Eine seit 2021 arbeitende gemeinnützige Non-Profit Organisation, die sich dem Ziel des Naturschutzes und der Förderung eines harmonischen Miteinanders von Mensch und Natur verschrieben hat."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/97781406-fd67-48a1-b9e9-35531dc96503/26880b92-dc51-45d7-8076-8acf5157488d?org_if_sml=1"
        />
      </Helmet>
      <div className="friedrich-und-partner-image">
        <Header></Header>
        <div className="friedrich-und-partner-bg"></div>
      </div>
      <div className="friedrich-und-partner-container1">
        <div className="friedrich-und-partner-container2">
          <img
            alt="image"
            src="/fp-200h.png"
            className="friedrich-und-partner-image1"
          />
        </div>
        <div className="friedrich-und-partner-container3">
          <h3 className="friedrich-und-partner-text">
            <span>Marc Friedrich</span>
            <br></br>
          </h3>
          <span className="friedrich-und-partner-text03">
            <span>
              „Für uns ist Diversifikation einer der wichtigsten Faktoren für
              Stabilität, gesundes Wachstum 
            </span>
            <span>
              und Widerstandsfähigkeit– und damit letztendlich auch für
              Freiheit. 
            </span>
            <span>
              Dies gilt nicht nur für das Finanzwesen, sondern natürlich auch
              für die Natur. 
            </span>
            <span>
              reNature People überträgt diesen Resilienz-Ansatz auf den
              Naturschutz, so wie wir ihn in auf 
            </span>
            <span>
              Themen wie Finanzen und Gesellschaft anwenden. Für uns daher der
              ideale Partner zum 
            </span>
            <span>Schutz unserer Natur. Gemeinsam für Mensch und Natur!“</span>
          </span>
          <a
            href="https://www.friedrich-partner.de/"
            target="_blank"
            rel="noreferrer noopener"
            className="friedrich-und-partner-text10"
          >
            Zur Webseite
          </a>
        </div>
      </div>
      <Footer rootClassName="footer-root-class-name1"></Footer>
    </div>
  )
}

export default FriedrichUndPartner
