import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Footer from '../components/footer'
import './vision.css'

const Vision = (props) => {
  return (
    <div className="vision-container">
      <Helmet>
        <title>
          Vision - reNature People: Naturschutz & Nachhaltigkeit für Mensch und
          Natur
        </title>
        <meta
          name="description"
          content="reNature People - Stärkung des harmonischen Zusammenlebens von Mensch, Tier und Natur. Naturnahe Umgestaltung, Renaturierung und Beratung für Landflächen. Kontaktieren Sie uns!"
        />
        <meta
          property="og:title"
          content="Vision - reNature People: Naturschutz &amp; Nachhaltigkeit für Mensch und Natur"
        />
        <meta
          property="og:description"
          content="reNature People - Stärkung des harmonischen Zusammenlebens von Mensch, Tier und Natur. Wir bieten naturnahe Umgestaltung, Renaturierung und Beratung für eine gesunde und nachhaltige Landflächengestaltung. Kontaktieren Sie uns für Hilfe und ein umfangreiches Leistungsportfolio."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/97781406-fd67-48a1-b9e9-35531dc96503/26880b92-dc51-45d7-8076-8acf5157488d?org_if_sml=1"
        />
      </Helmet>
      <div className="vision-hero">
        <Header rootClassName="header-root-class-name9"></Header>
        <div className="vision-bg"></div>
      </div>
      <div className="vision-post-details">
        <h3 className="vision-text">Visionen und Ziele</h3>
        <span className="vision-text01">
          Wir wollen durch unser Wirken das Bewusstsein und Verständnis für die
          organischen Zusammenhänge des Lebensraumes Erde stärken. Menschen,
          Tiere und Pflanzenwelt bewohnen alle denselben Planeten. Durch unser
          Wirken leisten wir einen Beitrag, die natürliche Tier- und
          Pflanzenwelt zu stärken und mit dem Menschen ein harmonisches
          Zusammenleben zu fördern.
        </span>
      </div>
      <div className="vision-container01">
        <div className="vision-story">
          <div className="vision-container02">
            <div className="vision-container03">
              <h1 className="vision-text02 TextXL">Die Vision</h1>
              <span className="vision-text03">
                Wir Menschen sind Teil des Lebensorganismus der Erde und stehen
                nicht außerhalb dessen. Im Zuge einer auf reine
                Gewinnmaximierung ausgelegten Wirtschaftsweise, möchte sich der
                Mensch die Welt, also alle anderen lebenden Geschöpfe, nutzbar
                machen. Dies geht nur dann, wenn der Mensch die Natur
                beherrscht. In vielen Bereichen ist diese Beherrschung, mit all
                ihren strukturellen Folgeerscheinungen für die Umwelt, mit einer
                nachhaltigen Zerstörung der Natur gleichzusetzen. Dadurch
                entsteht eine Störung des Gleichgewichtes innerhalb der Natur
                mit weitreichenden negativen Folgen für alle Beteiligten und
                schadet unserer aller Gesundheit. Wir von reNature People sehen
                den Menschen als Organ eines umfassenden Organismus, der die
                Gesamtheit unserer Welt beinhaltet. Unser Focus richtet sich
                demnach darauf, Welt und Mensch wieder zu versöhnen und in ein
                harmonisches und gesundes Miteinander zu begleiten. Ein gesunder
                Organismus definiert sich durch ein harmonisches, ausgewogenes
                Verhältnis aller ihn belebenden Organe. Fängt ein Organ an, die
                anderen einseitig auszubeuten, schädigt das den gesamten
                Organismus mit der Folge, dass der gesamte Organismus geschwächt
                wird und erkrankt – letztendlich auch das ausbeutende Organ.
                Kein Organ kann ohne die anderen überleben. Im Idealfall
                arbeiten alle Organe den jeweils anderen zu und stärken damit
                letztendlich auch sich selbst.
              </span>
            </div>
            <div className="vision-container04"></div>
          </div>
          <div className="vision-container05">
            <div className="vision-container06"></div>
            <div className="vision-container07">
              <h1 className="vision-text04 TextXL">Ziele</h1>
              <span className="vision-text05">
                Wir bieten Hilfe und Unterstützung bei der naturnahen
                Umgestaltung und Pflege von bestehenden Flächen wie Gärten,
                Grünflächen, Betriebsgeländen und Schulgärten sowie bei der
                Renaturierung von versiegelten oder verarmten Flächen. Wir sind
                Experten für Fragen rund um Landflächen, von Artenschutz bis
                Nachbarschaftsrecht und Zubehör im naturnahen Garten.
                Kontaktieren Sie uns für konkrete Hilfestellung. Wir übermitteln
                Ihnen gerne ein ausführliches Leistungsportfolio.
              </span>
              <div className="vision-container08">
                <div className="vision-container09">
                  <svg viewBox="0 0 1024 1024" className="vision-icon">
                    <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                  </svg>
                  <span className="vision-text06">Boden(re)kultivierung</span>
                </div>
                <div className="vision-container10">
                  <svg viewBox="0 0 1024 1024" className="vision-icon02">
                    <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                  </svg>
                  <span className="vision-text07">
                    <span>
                      Garten und Grünflächen naturnah
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <br></br>
                    <span>gestalten</span>
                  </span>
                </div>
                <div className="vision-container11">
                  <svg viewBox="0 0 1024 1024" className="vision-icon04">
                    <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                  </svg>
                  <span className="vision-text11">
                    Lebens- und Erlebnisräume schaffen
                  </span>
                </div>
                <div className="vision-container12">
                  <svg viewBox="0 0 1024 1024" className="vision-icon06">
                    <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                  </svg>
                  <span className="vision-text12">
                    <span>
                      Renaturierung versiegelter artenarmer
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <br></br>
                    <span>Bodenflächen</span>
                  </span>
                </div>
                <div className="vision-container13">
                  <svg viewBox="0 0 1024 1024" className="vision-icon08">
                    <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                  </svg>
                  <span className="vision-text16">
                    Biotop- und Streuobstwiesenpflege
                  </span>
                </div>
                <div className="vision-container14">
                  <svg viewBox="0 0 1024 1024" className="vision-icon10">
                    <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                  </svg>
                  <span className="vision-text17">
                    Schaffung von Bildungsangeboten
                  </span>
                </div>
                <div className="vision-container15">
                  <svg viewBox="0 0 1024 1024" className="vision-icon12">
                    <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                  </svg>
                  <span className="vision-text18">
                    <span>Beratung und Konzepterstellung für</span>
                    <br></br>
                    <span>Naturgärten und Biotope</span>
                    <br></br>
                  </span>
                </div>
                <div className="vision-container16">
                  <svg viewBox="0 0 1024 1024" className="vision-icon14">
                    <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                  </svg>
                  <span className="vision-text23">
                    <span>
                      Schaffung von Kooperativen unter
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <br></br>
                    <span>ökologischen Gesichtspunkten</span>
                  </span>
                </div>
                <div className="vision-container17">
                  <svg viewBox="0 0 1024 1024" className="vision-icon16">
                    <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                  </svg>
                  <span className="vision-text27">
                    Förderung von Permakulturen
                  </span>
                </div>
                <div className="vision-container18">
                  <svg viewBox="0 0 1024 1024" className="vision-icon18">
                    <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                  </svg>
                  <span className="vision-text28">
                    <span>Förderung von direkten Absatz-</span>
                    <br></br>
                    <span>
                      möglichkeiten für Produkte aus
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <br></br>
                    <span>nachhaltiger, biologischer Land- u.</span>
                    <br></br>
                    <span>Forstwirtschaft</span>
                  </span>
                </div>
                <div className="vision-container19">
                  <svg viewBox="0 0 1024 1024" className="vision-icon20">
                    <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                  </svg>
                  <span className="vision-text36">
                    <span>
                      Aufforstung und Renaturierung von
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <br></br>
                    <span>Regenwaldflächen</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Vision
