import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './header.css'

const Header = (props) => {
  return (
    <div data-role="Header" className={`header-header ${props.rootClassName} `}>
      <Link to="/" className="header-navlink">
        <img
          alt={props.image_alt}
          src={props.image_src}
          className="header-image"
        />
      </Link>
      <div className="header-container">
        <div className="header-container1">
          <Link to="/" className="header-navlink1">
            {props.renaturePeople}
          </Link>
        </div>
        <div className="header-container2">
          <Link to="/vision" className="header-navlink2">
            {props.visionenUndZiele}
          </Link>
        </div>
        <Link to="/leistungen" className="header-navlink3">
          <div className="header-container3">
            <span className="header-text">{props.text3}</span>
          </div>
        </Link>
        <Link to="/projekte" className="header-navlink4">
          <div className="header-container4">
            <span className="header-text1">{props.text1}</span>
          </div>
        </Link>
        <Link to="/spenden" className="header-navlink5">
          <div className="header-container5">
            <span className="header-text2">{props.text2}</span>
          </div>
        </Link>
      </div>
      <div data-role="BurgerMenu" className="header-burger-menu">
        <svg viewBox="0 0 1024 1024" className="header-icon">
          <path
            d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"
            className=""
          ></path>
        </svg>
      </div>








      <div data-role="MobileMenu" className="header-mobile-menu">
        <div className="header-top">
          <Link to="/" className="header-navlink6">
            <h1 className="header-heading TextSM">reNature People</h1>
          </Link>
          <div data-role="CloseMobileMenu" className="header-close-menu">
            <svg viewBox="0 0 1024 1024" className="header-icon02">
              <path
                d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
                className=""
              ></path>
            </svg>
          </div>
        </div>
        <div className="header-mid">
          <div className="header-container6">
            <Link to="/vision" className="header-navlink6">
              <span className="header-text3 TextSM">Visionen und Ziele</span>
            </Link>
          </div>
          <div className="header-container6">
            <Link to="/leistungen" className="header-navlink6">
              <span className="header-text3 TextSM">Leistungen</span>
            </Link>
          </div>
          <div className="header-container6">
            <Link to="/projekte" className="header-navlink6">
              <span className="header-text3 TextSM">Projekte</span>
            </Link>
          </div>
          <div className="header-container7">
            <Link to="/spenden" className="header-navlink6">
              <span className="header-text3 TextSM">Spenden</span>
            </Link>
          </div>
        </div>
      </div>











    </div>
  )
}

Header.defaultProps = {
  text: 'Leistungen',
  rootClassName: '',
  image_src: '/logo_renature_black%20on%20white%20background-2%202-200h1.png',
  text3: 'Leistungen',
  renaturePeople: 'reNature People',
  heading11: 'NOTUS PRO',
  text1: 'Projekte',
  text2: 'Spenden',
  Schwerpunkte: 'Schwerpunkte',
  visionenUndZiele: 'Visionen und Ziele',
  image_alt: 'image',
}

Header.propTypes = {
  text: PropTypes.string,
  rootClassName: PropTypes.string,
  image_src: PropTypes.string,
  text3: PropTypes.string,
  renaturePeople: PropTypes.string,
  heading11: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
  Schwerpunkte: PropTypes.string,
  visionenUndZiele: PropTypes.string,
  image_alt: PropTypes.string,
}

export default Header
