import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Footer from '../components/footer'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>
          reNature People: Naturschutz & Nachhaltigkeit für Mensch und Natur
        </title>
        <meta
          name="description"
          content="Willkommen bei reNature People - der gemeinnützigen Non-Profit Organisation für Naturschutz und harmonisches Miteinander von Mensch und Natur. Schütze die Natur, unterstütze unsere Projekte weltweit."
        />
        <meta
          property="og:title"
          content="reNature People: Naturschutz &amp; Nachhaltigkeit für Mensch und Natur"
        />
        <meta
          property="og:description"
          content="Eine seit 2021 arbeitende gemeinnützige Non-Profit Organisation, die sich dem Ziel des Naturschutzes und der Förderung eines harmonischen Miteinanders von Mensch und Natur verschrieben hat."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/97781406-fd67-48a1-b9e9-35531dc96503/26880b92-dc51-45d7-8076-8acf5157488d?org_if_sml=1"
        />
      </Helmet>
      <div className="home-hero">
        <Header></Header>
      </div>
      <div className="home-section1">
        <div className="home-container01">
          <div className="home-container02">
            <h3 className="home-text">Willkommen bei</h3>
            <h1 className="home-text01">reNature People</h1>
            <span className="home-text02">
              <span>
                Die reNature People gGmbH ist eine seit 2021 arbeitende
                gemeinnützige Non-Profit 
              </span>
              <span>
                Organisation, die sich dem Ziel des Naturschutzes und der
                Förderung eines 
              </span>
              <span>
                harmonischen Miteinanders von Mensch und Natur verschrieben
                hat. 
              </span>
              <span>
                Dieses Ziel verfolgen wir auf unterschiedlichen Ebenen: 
              </span>
              <span>
                von der Biotoppflege hier vor Ort bis zur Schaffung von
                Naturreservaten im 
              </span>
              <span>
                Regenwald, von der Unterstützung für die Direktvermarktung von
                biologischen 
              </span>
              <span>
                und nachhaltigen Wald- und Landwirtschaftsprodukten bis hin zur
                Schaffung 
              </span>
              <span>
                von Natur-Bildungsangeboten. reNature Poeple ist weltanschaulich
                und politisch neutral und keiner Gruppierung Partei oder
                sonstigen Organisation verpflichtet.
              </span>
            </span>
          </div>
          <div className="home-cards-container">
            <div className="home-card1">
              <h6 className="home-text11 TextXL">Leistungen</h6>
              <span className="home-text12">
                <span>
                  Wir bieten ein breites Spektrum von Dienstleistungen und
                  Unterstützung bei der 
                </span>
                <span>
                  Planung und Gestaltung bestehender Gärten, Grünflächen, usw.
                  an. Darüber hinaus 
                </span>
                <span>
                  schaffen wir Bildungsangebote im Bereich Naturschutz
                </span>
              </span>
              <Link to="/leistungen" className="home-navlink">
                Erfahren Sie mehr
              </Link>
            </div>
            <div className="home-card2">
              <h6 className="home-text16 TextXL">Visionen und Ziele</h6>
              <span className="home-text17">
                <span>
                  Die Vision und die Ziele von reNature People zielen auf ein
                  harmonisches 
                </span>
                <span>
                  Zusammenleben von Mensch, Tier und Pflanzen auf der Erde ab,
                  inklusive des nachhaltigen Schutzes der natürlichen Tier- und
                  Pflanzenwelt.
                </span>
              </span>
              <Link to="/vision" className="home-navlink">
                Erfahren Sie mehr
              </Link>
            </div>
            <div className="home-card3">
              <h6 className="home-text21 TextXL">Projekte</h6>
              <span className="home-text22">
                <span className="home-text23">
                  Unsere Projekte umfassen den Schutz von Naturräumen, die
                  nachhaltige Waldwirtschaft und die Zusammenarbeit mit
                  internationalen Partnern für das Wohl von Mensch und Natur.
                </span>
                <br></br>
                <br></br>
              </span>
              <Link to="/projekte" className="home-navlink">
                Erfahren Sie mehr
              </Link>
            </div>
          </div>
          <div className="home-work-with-us">
            <div className="home-container03">
              <h3 className="home-text27">Unser Vorhaben</h3>
              <span className="home-text28">
                <span>
                  Den Schutz der Natur verstehen wir als unsere Mission.
                </span>
                <br></br>
                <span>
                  Oftmals reicht der reine Schutz bestehender Naturräume aber 
                </span>
                <span>
                  nicht mehr aus, weil sie bereits vom Menschen beschädigt 
                </span>
                <span>
                  oder gar zerstört wurden. Wir führen diese Räume wieder in 
                </span>
                <span>
                  einen naturnahen bzw. natürlichen Zustand zurück. Die 
                </span>
                <span>natürliche Tier- und Pflanzenwelt darf sich wieder </span>
                <span>
                  regenerieren. Mensch und Natur können in Freundschaft 
                </span>
                <span>
                  miteinander leben. Das zeigen wir, indem wir Natur- und 
                </span>
                <span>
                  Lebensräume schaffen, in denen sich sowohl die Natur als 
                </span>
                <span>auch der Mensch wesensgemäß entwickeln können. Zur </span>
                <span>Nachhaltigkeit zählt auch dazu, Menschen durch </span>
                <span>
                  Bildungsangebote für die natürlichen Zusammenhänge zu 
                </span>
                <span>
                  sensibilisieren. Dieses Konzept setzen wir sowohl hier bei
                  uns 
                </span>
                <span>als auch in der Regenwaldregion um.</span>
                <br></br>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>reNature People - </span>
                <span>Gemeinsam für Mensch und Natur</span>
              </span>
            </div>
            <div className="home-container04">
              <div className="home-container05"></div>
              <div className="home-container06">
                <h3 className="home-text49">Unser Team</h3>
                <span className="home-text50">
                  <span>
                    Der Großteil unseres Teams kann auf eine langjährige
                    Erfahrung im Natur- und Umweltschutz zurückblicken. Wir sind
                    engagiert und motiviert, unsere vielfältigen Erfahrungen und
                    Kompetenzen hier bei reNature People für die Natur und den
                    Menschen einbringen zu können! Ein großes Dankeschön geht
                    auch an all unsere ehrenamtlichen Mitarbeiter und
                    Unterstützer!
                  </span>
                  <br></br>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-section2">
        <div className="home-container07">
          <div className="home-container08">
            <h1 className="home-text53">unsere helping hands</h1>
            <svg viewBox="0 0 1024 1024" className="home-icon">
              <path d="M512 442.667c0-103.083-83.584-186.667-186.667-186.667s-186.667 83.584-186.667 186.667c0 48.085 6.784 118.784 74.667 186.667s298.667 224 298.667 224 230.784-156.117 298.667-224 74.667-138.581 74.667-186.667c0-103.083-83.584-186.667-186.667-186.667s-186.667 83.584-186.667 186.667"></path>
            </svg>
          </div>
          <span className="home-text54">
            Vielen Dank für euere Unterstützung!
          </span>
        </div>
        <div className="home-team">
          <div className="home-container09">
            <div className="home-container10">
              <Link to="/friedrich-und-partner" className="home-navlink1">
                <div className="home-container11">
                  <img alt="image" src="/fp-200h.png" className="home-image" />
                  <h1 className="home-text55">Friedrich und Partner</h1>
                  <h1 className="home-text56 TextSM">Finanzberatung</h1>
                </div>
              </Link>
            </div>
            <div className="home-container12">
              <Link to="/cofymi" className="home-navlink2">
                <div className="home-container13">
                  <img
                    alt="image"
                    src="/cy2-200h.png"
                    className="home-image1"
                  />
                  <h1 className="home-text57 TextXL">Cofymi</h1>
                  <h1 className="home-text58 TextSM">Kaffeerösterei</h1>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Home
