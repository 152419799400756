import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './footer.css'

const Footer = (props) => {
  return (
    <div className={`footer-container ${props.rootClassName} `}>
      <div className="footer-container1">
        <div className="footer-container2">
          <h2 className="footer-text">{props.heading}</h2>
        </div>
        <div className="footer-container3">
          <svg viewBox="0 0 1024 1024" className="footer-icon">
            <path
              d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"
              className=""
            ></path>
          </svg>
          <a className="footer-text01" href="mailto:%69%6e%66%6f%40%72%65%6e%61%74%75%72%65%70%65%6f%70%6c%65%2e%64%65">Senden Sie uns eine E-Mail</a>
        </div>
        <div className="footer-container4">
          <svg viewBox="0 0 1024 1024" className="footer-icon2">
            <path
              d="M704 640c-64 64-64 128-128 128s-128-64-192-128-128-128-128-192 64-64 128-128-128-256-192-256-192 192-192 192c0 128 131.5 387.5 256 512s384 256 512 256c0 0 192-128 192-192s-192-256-256-192z"
              className=""
            ></path>
          </svg>
          <span className="footer-text02">{props.text2}</span>
        </div>
        <div className="footer-container5">
          <svg viewBox="0 0 950.8571428571428 1024" className="footer-icon4">
            <path
              d="M804.571 566.857v274.286c0 20-16.571 36.571-36.571 36.571h-219.429v-219.429h-146.286v219.429h-219.429c-20 0-36.571-16.571-36.571-36.571v-274.286c0-1.143 0.571-2.286 0.571-3.429l328.571-270.857 328.571 270.857c0.571 1.143 0.571 2.286 0.571 3.429zM932 527.429l-35.429 42.286c-2.857 3.429-7.429 5.714-12 6.286h-1.714c-4.571 0-8.571-1.143-12-4l-395.429-329.714-395.429 329.714c-4 2.857-8.571 4.571-13.714 4-4.571-0.571-9.143-2.857-12-6.286l-35.429-42.286c-6.286-7.429-5.143-19.429 2.286-25.714l410.857-342.286c24-20 62.857-20 86.857 0l139.429 116.571v-111.429c0-10.286 8-18.286 18.286-18.286h109.714c10.286 0 18.286 8 18.286 18.286v233.143l125.143 104c7.429 6.286 8.571 18.286 2.286 25.714z"
              className=""
            ></path>
          </svg>
          <span className="footer-text03">
            <span className="">reNature People gGmbH</span>
            <br className=""></br>
            <span className="">Hasenweg 5</span>
            <br className=""></br>
            <span className="">73099 Adelberg</span>
            <br className=""></br>
          </span>
        </div>
      </div>
      <div className="footer-container6"></div>
      <div className="footer-container7">
        <Link to="/impressum" className="footer-navlink">
          {props.text3}
        </Link>
        <Link to="/datenschutz" className="footer-navlink1">
          {props.text31}
        </Link>
        <Link to="/websiteby" className="footer-navlink2">
          {props.text4}
        </Link>
      </div>
      <Link to="/impressum" className="footer-navlink3 TextSM">
        Copyright © 2024 reNature People gGmbH
      </Link>
    </div>
  )
}

Footer.defaultProps = {
  text2: '+49 (0) 1551 0619801',
  text3: 'Impressum',
  text31: 'Datenschutzerklärung',
  heading: 'Kontakt',
  text4: 'Website by',
  rootClassName: '',
  link_text1: 'https://example.com',
  text: 'Text',
  text1: 'info@renaturepeople.de',
  link_text: 'https://example.com',
}

Footer.propTypes = {
  text2: PropTypes.string,
  text3: PropTypes.string,
  text31: PropTypes.string,
  heading: PropTypes.string,
  text4: PropTypes.string,
  rootClassName: PropTypes.string,
  link_text1: PropTypes.string,
  text: PropTypes.string,
  text1: PropTypes.string,
  link_text: PropTypes.string,
}

export default Footer
